<template>
  <div id="distribusionSearchWidget">
    <h2>{{ componentData.fields.widgetTitle }}</h2>
    <div id="distribusion-search"></div>
  </div>
</template>

<script>
export default {
  name: "DistribusionSearchWidget",
  props: ["componentData", "publicSiteSettings", "selectedLanguage"],
  data() {
    return {
      currency:
        this.publicSiteSettings.fields.globalLocale === "en-EU"
          ? "EUR"
          : this.publicSiteSettings.fields.globalLocale === "en-GB"
          ? "GBP"
          : "USD",
      locale: this.selectedLanguage === "en-us" ? "en" : this.selectedLanguage,
    };
  },
  mounted() {
    this.loadDistribusionWidgetScript();
    this.loadDistribusionWidgetLink();
    this.loadDistribusionWidgetEventListener();
  },
  watch: {
    selectedLanguage: "reloadPage",
  },
  methods: {
    loadDistribusionWidgetScript() {
      this.$nextTick(() => {
        const scriptElement = document.createElement("script");
        scriptElement.async = true;
        scriptElement.id = "distribusion-widget-script";
        scriptElement.src = "https://book.distribusion.com/sdk.1.0.0.js";
        const placeholder = document.getElementById("distribusionSearchWidget");
        placeholder.appendChild(scriptElement);
      });
    },
    loadDistribusionWidgetLink() {
      this.$nextTick(() => {
        const scriptElement = document.createElement("link");
        scriptElement.id = "distribusion-widget-link";
        scriptElement.href = "https://book.distribusion.com/sdk.1.0.0.css";
        scriptElement.rel = "stylesheet";
        const placeholder = document.getElementById("distribusionSearchWidget");
        placeholder.appendChild(scriptElement);
      });
    },
    loadDistribusionWidgetEventListener() {
      window.addEventListener("load", () => {
        const root = document.getElementById("distribusion-search");
        /* eslint-disable */
        Distribusion.Search.mount({
          /* eslint-enable */
          root,
          partnerNumber: parseInt(
            this.publicSiteSettings.fields.distribusionWidgetPartnerNumber
          ),
          locale: this.locale,
          currency: this.currency,
          defaults: {
            departureStation: this.componentData.fields.departureStation,
            departureArea: this.componentData.fields.departureArea,
            departureCity: this.componentData.fields.departureCity,

            arrivalStation: this.componentData.fields.arrivalStation,
            arrivalArea: this.componentData.fields.arrivalArea,
            arrivalCity: this.componentData.fields.arrivalCity,
          },
        });
      });
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
